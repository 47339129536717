// ssoPlugin.js
import apis from "@/api"; // 导入你的 API 模块

export default {
  install(Vue) {
    Vue.prototype.$checkSSO = async function() {
      try {
        const response = await apis.getUserProfile();
      } catch (error) {
        console.error('SSO check failed:', error);
      }
    };
  }
};
